import React from 'react';
import Container from '@material-ui/core/Container';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

function Terms({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Terms of use chordsl.com" />
      <Container maxWidth="md">
        <h1 id="chordsl-is-here">Terms of use</h1>

        <p>
          This page contains the “Terms of Use” under which you may use the site
          (chordsl.com, www.chordsl.com). It is important that you read this
          page carefully. If for any reason you do not agree with the terms
          stated here and do not accept them please do not continue to use the
          site or its features and services. By continuing to use the site you
          are indicating your acceptance to be bound by the Terms of
          Use/Service. This page maybe revised as the site implements new
          functionality and as such it is advised that you visit this page
          periodically to review the Terms of Use/Service.
        </p>

        <p>
          This site (chordsl.com, www.chordsl.com) offers various functionality
          that enables visitors to search, view, add and modify content on the
          site primarily identified as “Guitar Chords”, “Chords” , “Song
          Lyrics”, “Lyrics”. Chords and Lyrics are identified on the site as a
          narration of spoken word often in a song like style or form which may
          or may not be accompanied by music. The site also makes additional
          functionality possible such as the ability to view related videos,
          save playlists, link content to other social networking sites and
          email content or links.
        </p>

        <p>
          By using (chordsl.com, www.chordsl.com) you agree not to defeat, evade
          or circumvent any system or feature offered on (chordsl.com,
          www.chordsl.com). This includes but are not limited to, using email
          systems for spamming, adding invalid lyrics, modifying existing chords
          and lyrics with invalid information. Spamming social networks with
          (chordsl.com, www.chordsl.com) links, hot linking to site content,
          abusing third party advertisements or attempting to cause malicious
          activity against the site such as hacking.
        </p>
      </Container>
    </Layout>
  );
}

export default Terms;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
